<template>
  <div class="fixed left-0 z-50 top-14 pl-3">
    <label
      for="navigation"
      class="fixed outline-none bottom-0 right-3 z-50 flex items-center justify-center w-12 h-12 mb-4 ml-4 bg-white border rounded-full shadow-lg cursor-pointer text-slate-600 border-slate-300 lg:hidden transition duration-200 ease-in-out active:scale-95"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4 8h16M4 16h16"
        />
      </svg>
    </label>

    <input
      type="checkbox"
      name="navigation"
      id="navigation"
      class="hidden peer outline-none"
      v-model="isChecked"
    />

    <div
      class="top-0 fixed right-0 left-0 bottom-0 bg-gray-900 transition-opacity duration-200 sm:hidden pointer-events-none"
      :class="isChecked ? 'pointer-events-auto bg-opacity-75' : 'bg-opacity-0'"
      @click="isChecked = false"
    />
    <div
      class="fixed top-[3.5rem] h-screen w-64 z-20 shadow-xl px-4 left-0 hidden peer-checked:block lg:relative lg:top-0 lg:h-auto lg:px-0 lg:block lg:flex-none lg:shadow-none"
    >
      <div
        class="absolute inset-y-0 right-0 w-full lg:w-[50vw] bg-white lg:bg-slate-50"
      />

      <nav
        class="sticky top-[4.5rem] w-64 pr-8 text-base lg:text-sm xl:w-72 xl:pr-16"
      >
        <ul
          role="list"
          class="-ml-0.5 h-[calc(100vh-4.5rem)] overflow-y-auto py-7 pl-0.5 space-y-8"
        >
          <li>
            <h3 class="font-semibold tracking-tight text-slate-900">Начало</h3>

            <ul role="list" class="pl-3 mt-3 space-y-2">
              <li v-for="menu in Menu" :key="menu.path">
                <a
                  :href="menu.path"
                  class="text-app-color-link hover:underline"
                >
                  {{ menu.name }}
                </a>
                <ul class="pl-6 mt-3 space-y-2">
                  <li v-for="subMenu in menu.subMenu" :key="subMenu.path">
                    <router-link
                      :to="subMenu.path"
                      class="text-app-color-link hover:underline"
                      >{{ subMenu.name }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { Menu } from "@/components/LeftNavigation/data/data";

let isChecked = ref(false);
</script>
