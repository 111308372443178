<script setup lang="ts"></script>

<template>
  <header
    class="sticky top-0 z-50 h-14 flex items-center justify-between px-3 py-2 border-b shadow-md bg-white/90 backdrop-blur-sm border-slate-400/40"
  >
    <div class="flex items-center flex-grow basis-0 space-x-2">
      <img src="@/assets/logo.svg" class="w-6" alt="logo" />

      <a href="/#/" class="text-lg font-semibold tracking-tight text-slate-900">
        Force Help
      </a>
    </div>

    <div class="items-center justify-end flex-grow hidden basis-0 md:flex">
      <a
        href="/#/"
        class="px-4 py-2 text-sm font-semibold rounded bg-slate-900 text-slate-50 transition ease-in-out delay-75 hover:scale-105 duration-200"
      >
        На главную
      </a>
    </div>
  </header>
</template>

<style scoped lang="scss"></style>
