import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/tailwind.css";
import VueLazyLoad from "vue3-lazyload";
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";

createApp(App)
  .use(VueViewer)
  .use(VueLazyLoad, {
    observerOptions: {
      rootMargin: "0px 0px 0px 0px",
      threshold: 0,
    },
  })
  .use(router)
  .mount("#app");
