import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/docs/auth",
    name: "DocsAuth",
    component: () => import("../views/LoginAndRegister/DocsAuth/DocsAuth.vue"),
  },
  {
    path: "/docs/register",
    name: "DocsRegister",
    component: () =>
      import("../views/LoginAndRegister/DocsRegister/DocsRegister.vue"),
  },
  {
    path: "/docs/install",
    name: "InstallGuide",
    component: () => import("../views/Install/InstallGuide.vue"),
  },
  {
    path: "/docs/add-sbp",
    name: "AddSbp",
    component: () => import("../views/AddSbp/AddSbp.vue"),
  },
  {
    path: "/docs/addcard",
    name: "AddCard",
    component: () => import("../views/AddCard/AddCard.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return { left: 0, top: 0 };
  },
});

export default router;
