interface IMenu {
  name: string;
  path: string;
  hash?: string;
  subMenu?: IMenu[];
}

export const Menu: IMenu[] = [
  {
    name: "Как добавить карту",
    path: "/#/docs/addcard",
  },
  {
    name: "Как добавить СБП",
    path: "/#/docs/add-sbp",
  },
  {
    name: "Как установить приложение",
    path: "/#/docs/install",
    subMenu: [
      {
        name: "На Андройд",
        path: "/docs/install",
      },
      {
        name: "На Айфон",
        path: "/docs/install#iphone",
      },
    ],
  },
  {
    path: "/#/docs/register",
    name: "Регистрация в приложении",
  },
  {
    name: "Авторизация",
    path: "/#/docs/auth",
  },
];
